import React from 'react';
import { HeaderWrapper, LogoLink, Logo, UtilNav, NavLink, HeaderContent, MerckLogoWrapper } from './styles';
import { headerCopy } from '../../../copy/global';
import LogoImage from '../../../assets/images/Merck_Logo_RGB.svg';
import HeroTopImage from '../../../assets/images/TheMerckAccessProgram.png';

const Header = (props) => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContent>
          <LogoLink to="/"><Logo src={LogoImage} alt=""/></LogoLink>
        </HeaderContent>
      </HeaderWrapper>
      <MerckLogoWrapper><img src={HeroTopImage} alt=""/></MerckLogoWrapper>
      </>
  );
};

export default Header;
